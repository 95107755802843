.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: #12121a;
}
.home-hero {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  min-height: 80vh;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-container1 {
  top: 300px;
  margin: auto;
  display: flex;
  position: absolute;
  flex-direction: column;
}
.home-text {
  color: var(--dl-color-gray-white);
  font-size: 4rem;
  max-width: 450px;
  align-self: flex-start;
  padding-top: var(--dl-space-space-twounits);
}
.home-text3 {
  color: var(--dl-color-gray-white);
  font-size: 2rem;
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-link {
  display: contents;
}
.home-image {
  width: 100px;
  align-self: flex-start;
  object-fit: cover;
  border-color: var(--dl-color-gray-black);
  text-decoration: none;
}
@media(max-width: 614px) {
  .home-hero {
    height: 919px;
  }
  .home-container1 {
    left: 0px;
    right: 0px;
    height: 188px;
    margin: auto;
  }
  .home-text {
    align-self: center;
    padding-left: 0px;
  }
  .home-text3 {
    color: var(--dl-color-gray-white);
    font-size: 2rem;
    align-self: center;
    padding-left: 0px;
  }
  .home-image {
    top: 0px;
    left: 0px;
    right: 0px;
    height: 143px;
    margin: auto;
    position: absolute;
    align-self: flex-start;
    padding-top: var(--dl-space-space-5);
  }
}
@media(max-width: 517px) {
  .home-hero {
    width: 100%;
    height: 949px;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-container1 {
    top: 200px;
    width: 100%;
    height: 721px;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
  }
  .home-text {
    font-size: 4rem;
    max-width: auto;
    align-self: center;
    text-align: center;
    padding-left: 0px;
  }
  .home-text1 {
    color: var(--dl-color-gray-white);
  }
  .home-text2 {
    color: var(--dl-color-gray-white);
  }
  .home-text3 {
    width: auto;
    align-self: flex-start;
    text-align: center;
  }
  .home-image {
    top: var(--dl-space-space-oneandhalfunits);
    left: 0px;
    right: 0px;
    margin: auto;
  }
}
